import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const ChartsTableContainer = Loadable(() => import("./ChartsTableContainer"));

function ChartsTableContainerAsyncLoading(props) {
  return (
    <LoadableLoading
      key="chartsListLoadingOverlay"
      withPageHeader
      height={"136rem"}
    />
  );
}

const ChartsTableContainerContainerAsync = (props) => {
  return (
    <ChartsTableContainer
      {...props}
      fallback={<ChartsTableContainerAsyncLoading />}
    />
  );
};

export default ChartsTableContainerContainerAsync;

import React from "react";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";

const baseStyles = {
  skeletonContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  skeletonWrapper: {
    ...gStyles.skeletonPlaceholder,
    height: "12.938rem",
    width: "100%",
    borderRadius: "0.313rem",
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",

    padding: "18px",
    borderRadius: "20px",
    marginBottom: "1.5rem",
  },
};

export default function LoadingEntityCards(props) {
  const { styles, css } = useStyles(baseStyles, props);

  return (
    <div
      className={css(styles.skeletonContainer)}
      data-testid="skeleton-container"
    >
      <div
        className={css(styles.skeletonWrapper)}
        data-testid="skeleton-wrapper"
      />
      <div
        className={css(styles.skeletonWrapper)}
        data-testid="skeleton-wrapper"
      />
      <div
        className={css(styles.skeletonWrapper)}
        data-testid="skeleton-wrapper"
      />
      <div
        className={css(styles.skeletonWrapper)}
        data-testid="skeleton-wrapper"
      />
      <div
        className={css(styles.skeletonWrapper)}
        data-testid="skeleton-wrapper"
      />
      <div
        className={css(styles.skeletonWrapper)}
        data-testid="skeleton-wrapper"
      />
      <div
        className={css(styles.skeletonWrapper)}
        data-testid="skeleton-wrapper"
      />
      <div
        className={css(styles.skeletonWrapper)}
        data-testid="skeleton-wrapper"
      />
      <div
        className={css(styles.skeletonWrapper)}
        data-testid="skeleton-wrapper"
      />
      <div
        className={css(styles.skeletonWrapper)}
        data-testid="skeleton-wrapper"
      />
    </div>
  );
}

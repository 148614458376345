import PropTypes from "prop-types";
import { useEffect, useMemo } from "react";
import { loadChartsPodcastsPromise } from "routines/charts";

import LoadingEntityBasedOnLayout from "components/Common/LoadingEntityBasedOnLayout";

import ChartsTableContainer from "./ChartsTableContainerAsync";

import useChartData from "hooks/useChartData";
import useRoutinePromises from "hooks/useRoutinePromises";

const ChartsListContainer = (props) => {
  const { platform, country, category, date, podcastCellStyles } = props;

  const { loadChartsPodcasts } = useRoutinePromises({
    loadChartsPodcasts: loadChartsPodcastsPromise,
  });

  const { spotifyPodcasts, applePodcasts, loading } = useChartData();

  useEffect(() => {
    loadChartsPodcasts({
      platform,
      country,
      category,
      date,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platform, country, category, date]);

  const activeList = useMemo(() => {
    switch (platform) {
      case "apple": {
        return applePodcasts;
      }
      case "spotify": {
        return spotifyPodcasts;
      }

      default:
        return applePodcasts;
    }
  }, [applePodcasts, platform, spotifyPodcasts]);

  if (loading || !activeList || activeList.size === 0) {
    return <LoadingEntityBasedOnLayout layout={"list"} />;
  }

  return (
    <ChartsTableContainer
      platform={platform}
      country={country}
      category={category}
      activeList={activeList}
      podcastCellStyles={podcastCellStyles}
    />
  );
};

ChartsListContainer.propTypes = {
  localFilter: PropTypes.func,
  staticFilters: PropTypes.object,
};

export default ChartsListContainer;

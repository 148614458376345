import { faEllipsisH } from "@fortawesome/free-solid-svg-icons/faEllipsisH";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  table: {
    display: "table",
    borderCollapse: "separate",
    boxSizing: "border-box",
    textIndent: "initial",
    borderColor: "gray",
    width: "100%",
    position: "relative",
    borderRadius: "0.5rem",
    borderSpacing: 0,
  },
  headerContainer: {
    display: "grid",
    border: "1px solid #e6e1ea",
    justifyItems: "start",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    gridTemplateColumns: "14rem 6.5rem 4.5rem 4.49rem 3.344rem",

    alignItems: "center",
    padding: "0 0 0 1rem",
    minHeight: "2.375rem",
    [ScreenSizes.xxlAndAbove]: {
      gridTemplateColumns: "16rem 8.5rem 6.5rem 6.49rem 4.344rem",
    },
  },
  header: {
    borderLeft: "1px solid #e9e9e9",
    padding: "0.75rem 0 0.625rem 0rem",
    margin: "-1px 0",
    width: "100%",
    height: "100%",
    textAlign: "start",
    ":first-child": {
      borderLeft: "none",
      paddingLeft: "0",
    },
    display: "flex",
    justifyContent: "center",
  },
  rowContainer: {
    display: "grid",
    gridTemplateColumns: "14rem 6.5rem 4.5rem 4.49rem 5.344rem",

    alignItems: "center",
    borderTop: `none`,
    border: `1px solid ${colours.lightGreyBorder}`,
    padding: "0 1rem",
    borderLeft: `1px solid ${colours.outsideBorder}`,
    borderRight: `1px solid ${colours.outsideBorder}`,
    borderBottom: `1px solid ${colours.insideBorder}`,
    ":last-child": {
      borderBottomLeftRadius: "8px",
      borderBottomRightRadius: "8px",
      border: "1px solid #e6e1ea",
    },
    [ScreenSizes.xxlAndAbove]: {
      gridTemplateColumns: "16rem 8.5rem 6.5rem 6.49rem 7.344rem",
    },
  },
  cell: {
    padding: "0.625rem",
    height: "100%",
    display: "grid",
    alignItems: "Center",
    justifyItems: "start",
    minHeight: "5.313rem",
    borderLeft: "1px solid #e9e9e9",
    ":first-child": {
      borderLeft: "none",
      paddingLeft: "0",
    },
  },

  text: {
    ...gStyles.skeletonPlaceholder,
    height: ".8rem",
    borderRadius: "0.3rem",
    width: "85%",
  },
  firstText: {
    marginRight: "0.938rem",
  },
  icon: {
    ...gStyles.skeletonPlaceholder,
    height: "1rem",
    width: "1rem",
    borderRadius: "50%",
    margin: "auto",
  },
  squareIcon: {
    borderRadius: "0.2rem",
    height: "1.5rem",
    width: "1.5rem",
    margin: "unset",
  },
  image: {
    ...gStyles.skeletonPlaceholder,
    width: "3.125rem",
    height: "3.125rem",
    borderRadius: "0.313rem",
  },
  entity: {
    display: "flex",
    width: "100%",
  },
  textPlaceholder: {
    width: "100%",
    height: ".6rem",
  },
  textWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
    width: "85%",
    justifyContent: "center",
  },
  pill: {
    ...gStyles.skeletonPlaceholder,
    borderRadius: "0.875rem",
    width: "1.875rem",
    height: "1.063rem",
    margin: "auto",
  },
  dotWrapper: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
    marginLeft: "1rem",
  },
  ellipsis: {
    color: "#f4f4f4",
  },
};

export default function LoadingEntityTable(props) {
  const { styles, css } = useStyles(baseStyles, props);

  const renderRows = () => {
    const rows = [];
    for (let i = 0; i < 25; i++) {
      rows.push(
        <tr className={css(styles.rowContainer)} key={i}>
          <td data-testid="cell" className={css(styles.cell)}>
            <div className={css(styles.entity)}>
              <div className={css(styles.image)} />
              <div className={css(styles.textWrapper)}>
                <div className={css(styles.text, styles.textPlaceholder)} />
                <div className={css(styles.text, styles.textPlaceholder)} />
              </div>
            </div>
          </td>
          <td data-testid="cell" className={css(styles.cell)}>
            <div className={css(styles.textWrapper)}>
              <div className={css(styles.text, styles.textPlaceholder)} />
              <div className={css(styles.text, styles.textPlaceholder)} />
              <div className={css(styles.text, styles.textPlaceholder)} />
            </div>
          </td>
          <td data-testid="cell" className={css(styles.cell)}>
            <div className={css(styles.pill)} />
          </td>
          <td data-testid="cell" className={css(styles.cell)}>
            <div className={css(styles.icon)} />
          </td>
          <td data-testid="cell" className={css(styles.cell)}>
            <div className={css(styles.dotWrapper)}>
              <div className={css(styles.icon, styles.squareIcon)} />
              <FontAwesomeIcon
                className={css(styles.ellipsis)}
                icon={faEllipsisH}
              />
              <div />
            </div>
          </td>
        </tr>
      );
    }
    return rows;
  };

  return (
    <table className={css(styles.table)}>
      <thead>
        <tr className={css(styles.headerContainer)}>
          <th className={css(styles.header)}>
            <div className={css(styles.text, styles.firstText)} />
          </th>
          <th className={css(styles.header)}>
            <div className={css(styles.text)} />
          </th>
          <th className={css(styles.header)}>
            <div className={css(styles.icon)} />
          </th>
          <th className={css(styles.header)}>
            <div className={css(styles.icon)} />
          </th>
          <th className={css(styles.header)}></th>
        </tr>
      </thead>

      <tbody>{renderRows()}</tbody>
    </table>
  );
}

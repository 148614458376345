import React from "react";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  skeletonContainer: {
    display: "grid",
    gridTemplateColumns: "auto auto auto",
    margin: "0 -0.75rem -1rem",
    padding: "0px 0.625rem",

    [ScreenSizes.smAndAbove]: {
      display: "flex",
      flexFlow: "row wrap",
      justifyContent: "space-between",
    },
  },
  skeletonWrapper: {
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",

    borderRadius: "20px",
    margin: "0px 0 1rem",
    marginBottom: "1.5rem",
    width: "95%",
    flex: "0 0 48%",
    [ScreenSizes.smAndAbove]: {
      height: "13.5rem",
      width: "48%",
      flex: "0 0 48%",
    },
    [ScreenSizes.mdAndAbove]: {
      width: "24%",
      flex: "0 0 24%",
    },
    [ScreenSizes.lgAndAbove]: {
      marginBottom: "1.5rem",
      width: "48%",
      flex: "0 0 48%",
    },
    [ScreenSizes.xlAndAbove]: {
      width: "24%",
      flex: "0 0 24%",
    },
  },
  skeletonInnerWrapper: {
    ...gStyles.skeletonPlaceholder,
    width: "100%",
    marginBottom: ".3rem",
    height: "6.375rem",

    [ScreenSizes.smAndAbove]: {
      height: "9.375rem",
    },
  },
  text: {
    ...gStyles.skeletonPlaceholder,
    width: "100%",
    height: "0.8rem",
    marginTop: "0.5rem",
    borderRadius: "0.313rem",
  },
};

export default function LoadingEntityGallery(props) {
  const { styles, css } = useStyles(baseStyles, props);

  const skeletonItems = [];
  for (let i = 0; i < 24; i++) {
    skeletonItems.push(
      <div className={css(styles.skeletonWrapper)} key={i}>
        <div
          className={css(styles.skeletonInnerWrapper)}
          data-testid="skeleton-wrapper"
        />
        <div className={css(styles.text)} />
        <div className={css(styles.text)} />
      </div>
    );
  }

  return (
    <div
      className={css(styles.skeletonContainer)}
      data-testid="skeleton-container"
    >
      {skeletonItems}
    </div>
  );
}

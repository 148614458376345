import LoadingEntityCards from "./LoadingEntityCards";
import LoadingEntityGallery from "./LoadingEntityGallery";
import LoadingEntityTable from "./LoadingEntityTable";

export default function LoadingEntityBasedOnLayout(props) {
  const { layout, mobile } = props;
  switch (layout) {
    case "gallery":
      return <LoadingEntityGallery />;
    case "cards":
      return <LoadingEntityCards />;
    case "list":
      return !mobile ? <LoadingEntityTable /> : <LoadingEntityCards />;
    default:
      return null;
  }
}

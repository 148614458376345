import {
  selectApplePodcasts,
  selectSpotifyPodcasts,
  selectChartsLoading,
} from "selectors/charts";

import useReduxState from "hooks/useReduxState";

export function useChartData() {
  const applePodcasts = useReduxState(
    (state) => selectApplePodcasts(state),
    []
  );
  const spotifyPodcasts = useReduxState(
    (state) => selectSpotifyPodcasts(state),
    []
  );

  const loading = useReduxState((state) => selectChartsLoading(state), []);

  return {
    applePodcasts,
    spotifyPodcasts,

    loading,
  };
}

export default useChartData;
